import { useNavigate, useLocation } from "react-router-dom";
import { useSession } from "../../api/context/SessionContext";
import { useEffect } from "react";
import { useShoppingCart } from "../../api/context/ShoppingCartContext";

const LogoutPage = () => {
  const session = useSession();
  const shoppingCart = useShoppingCart();

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const returnUrl = queryParams.get("returnUrl") || "../";

  useEffect(() => {
    shoppingCart.emptyCart();
    session.logOut();
    console.log("logout");
    console.log("cart", shoppingCart.cart);
    navigate(returnUrl);
  }, []);

  return <div></div>;
};

export default LogoutPage;
