import React from "react";
import { Button, Modal } from "react-bootstrap";

interface ErrorModalProps {
  errorMessage: React.ReactNode;
  onOkClick?: () => void;
  onRetryClick?: () => void;
}
const ErrorModal = ({
  errorMessage,
  onOkClick,
  onRetryClick,
}: ErrorModalProps) => {
  return (
    <div className="modal show">
      <Modal show={true} size="lg" centered>
        <Modal.Header>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {typeof errorMessage === "string"
              ? errorMessage.split(". ").map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    {index < errorMessage.split(". ").length - 1 && "."}
                    {index < errorMessage.split(". ").length - 1 && <br />}
                  </React.Fragment>
                ))
              : errorMessage}
          </p>
        </Modal.Body>
        <Modal.Footer>
          {onRetryClick && (
            <Button variant="primary" onClick={onRetryClick}>
              Reintentar
            </Button>
          )}
          {onOkClick && (
            <Button variant="primary" onClick={onOkClick}>
              Aceptar
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ErrorModal;
