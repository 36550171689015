import { tShoppingCartContext } from "../context/ShoppingCartContext";
import { tSession } from "../../api/models/sessionModel";
import { BuildUrl, CreateAxiosInstance } from "../helpers/apiHelper";
import { NewGuid } from "../helpers/stringHelpers";
import {
    CartItem,
    ShoppingCart,
    eDeliveryType,
    ePaymentMethod,
} from "../models/cartModel";
import { tOrder } from "../models/orderModel";

const axiosInstance = CreateAxiosInstance();

export const CreateShoppingCart = (): ShoppingCart => {
    let cart: ShoppingCart = {
        id: NewGuid(),
        items: [],
        customer: { userId: "", name: "", email: "", phone: "" },
        deliveryType: eDeliveryType.Pickup,
        deliveryAddress: {
            street: "",
            city: "",
            state: "",
            zipCode: "",
            outsideNumber: "",
            innerNumber: "",
            neighborhood: "",
            country: "",
            lat: null,
            lng: null,
        },
        paymentMethod: ePaymentMethod.Card,
        comments: "",
    };
    localStorage.setItem("cartId", cart.id);
    return cart;
};

export const GetShoppingCart = async (): Promise<ShoppingCart> => {
    let cartId = localStorage.getItem("cartId");
    if (!cartId) return CreateShoppingCart();

    try {
        let response = await axiosInstance.get(
            BuildUrl(`/shoppingcart/${cartId}`)
        );
        if (response.status === 200) {
            const data: ShoppingCart = response.data as ShoppingCart;
            data.items = data.items as CartItem[];
            data.coupon = data.coupon || undefined;

            return data;
        }
    } catch (error) {
        console.log("cart error", error);
        ResetCart();
    }

    return CreateShoppingCart();
};

export const AddOrUpdateShoppingCartItem = async (
    cartId: string,
    productId: number,
    quantity: number
) => {
    let response = await axiosInstance.post(
        BuildUrl(`/shoppingcart/${cartId}/items`),
        {
            productId,
            quantity,
        }
    );
    if (response.status === 200) return response.data;

    throw new Error(
        "Error al agregar el producto al carrito" + response.status
    );
};

export const RemoveShoppingCartItem = async (
    cartId: string,
    productId: number
) => {
    let response = await axiosInstance.delete(
        BuildUrl(`/shoppingcart/${cartId}/items/${productId}`)
    );
    if (response.status === 200) return response.data;

    throw new Error(
        "Error al quitar el producto del carrito" + response.status
    );
};

export const EmptyShoppingCartItems = async (cartId: string) => {
    let response = await axiosInstance.delete(
        BuildUrl(`/shoppingcart/${cartId}`)
    );
    if (response.status === 200) return response.data;

    throw new Error("Error al vaciar el carrito" + response.status);
};

export const ResetCart = () => {
    localStorage.removeItem("cartId");
};

export const SetShoppingCartItemNotes = async (
    cartId: string,
    productId: number,
    notes: string
) => {
    let response = await axiosInstance.post(
        BuildUrl(`/shoppingcart/${cartId}/items`),
        {
            productId,
            notes,
        }
    );
    if (response.status === 200) return response.data;

    throw new Error("Error al agregar las notas al producto" + response.status);
};

export const ApplyCoupon = async (cartId: string, couponCode: string) => {
    let response = await axiosInstance.post(
        BuildUrl(`/shoppingcart/${cartId}/coupon/${couponCode}`),
        {}
    );
    if (response.status === 200) return response.data;

    throw new Error("Error al aplicar el cupón" + response.status);
};

export const RemoveCoupon = async (cartId: string) => {
    let response = await axiosInstance.delete(
        BuildUrl(`/shoppingcart/${cartId}/coupon`)
    );
    if (response.status === 200) return response.data;

    throw new Error("Error al quitar el cupón" + response.status);
};

export type CreateOrderResponse = {
    order: tOrder;
    success: boolean;
    message: string;
};

export const CreateOrder = async (
    cartContext: tShoppingCartContext,
    session: tSession
) => {
    try {
        const cart = cartContext.cart;
        const request = {
            cartId: cart.id,
            name: cart.customer.name,
            phone: cart.customer.phone,
            email: cart.customer.email,
            deliveryType: cart.deliveryType,
            deliveryFee: cartContext.getDeliveryFee(),
            address: cart.deliveryAddress,
            notes: cart.comments,
            userId: session.userId,
            paymentMethod: cart.paymentMethod,
        };
        const response = await axiosInstance.post(
            BuildUrl(`shoppingcart/${cartContext.cart.id}/order`),
            request
        );

        return response.data as CreateOrderResponse;
    } catch (error) {
        console.log("Error creating order", error);
        throw error;
    }
};
