import axios from "axios";
import { tAddress } from "../../components/Address/AddressInput";
import { BuildUrl, CreateAxiosInstance } from "../helpers/apiHelper";

const axiosInstance = CreateAxiosInstance();

export type CreateUserAccountResponse = {
    success: boolean;
    message: string;
    userId: string;
};

export const CreateUserAccount = async (
    email: string,
    password: string,
    confirmPassword: string,
    name: string,
    phone: string,
    address: tAddress,
    subscribeToEmail: boolean
) => {
    try {
        const response = await axiosInstance.put(BuildUrl("users"), {
            email: email,
            password: password,
            confirmPassword: password,
            name,
            phone,
            address,
            subscribeToEmail,
        });

        return response.data as CreateUserAccountResponse;
    } catch (error) {
        console.log("Error creating user account", error);
        throw error;
    }
};

export type ValidateCreateUserAccountResponse = {
    success: boolean;
    message: string;
    invalidProperty: string;
};

export const ValidateCreateUserAccount = async (
    email: string,
    password: string,
    confirmPassword: string
) => {
    try {
        const response = await axiosInstance.post(BuildUrl("users/validate"), {
            email: email,
            password: password,
            confirmPassword: confirmPassword,
        });

        return response.data as ValidateCreateUserAccountResponse;
    } catch (error) {
        console.log("Error validating user account", error);
        throw error;
    }
};
export type SetUserInfoResponse = {
    success: boolean;
    message: string;
};

export const SetUserInfo = async (
    userId: string,
    name: string,
    phone: string,
    address: tAddress,
    subscribeToEmail: boolean
) => {
    try {
        const response = await axiosInstance.post(BuildUrl(`users/${userId}`), {
            name: name,
            phone: phone,
            address: address,
            subscribeToEmail: subscribeToEmail,
        });

        return response.data as SetUserInfoResponse;
    } catch (error) {
        console.log("Error setting user info", error);
        throw error;
    }
};

export const Login = async (email: string, password: string) => {
    try {
        const response = await axiosInstance.post(BuildUrl("users/login"), {
            email: email,
            password: password,
            guest: true,
        });

        return response.data as LoginResponse;
    } catch (error) {
        console.log("Error login", error);
        throw error;
    }
};

export type LoginResponse = {
    success: boolean;
    message: string;
    token: string;
};
