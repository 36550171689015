import { FunctionComponent, useEffect, useRef } from "react";
import { Form } from "react-bootstrap";

interface SearchEntryProps {
  onChange: (value: string) => void;
  value?: string;
  autoFocus?: boolean;
}

const SearchEntry: FunctionComponent<SearchEntryProps> = (props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (props.autoFocus && inputRef.current)
      (inputRef.current as HTMLElement).focus();
  }, []);

  return (
    <Form id="SearchEntry">
      <Form.Control
        ref={inputRef}
        placeholder="buscar ..."
        value={props.value}
        type="search"
        onChange={(e) => props.onChange(e.target.value)}
      />
    </Form>
  );
};

export default SearchEntry;
