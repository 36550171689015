import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";

import { FeaturedProducts } from "../../api/services/ProductServices";
import Carousel from "../../components/Carousel/Carousel";
import CategoriesGrid from "../../components/Categories/CategoriesGrid";
import Footer from "../../components/Footer/Footer";
import HorizontalLane from "../../components/Layout/HorizontalLane";
import ProductCard from "../../components/ProductCard/ProductCard";
import SearchEntry from "../../components/SearchEntry/SearchEntry";

import { useStoreContext } from "../../api/context/StoreContext";

interface LandingPageProps {}

const LandingPage: FunctionComponent<LandingPageProps> = () => {
    const navigate = useNavigate();
    const { store } = useStoreContext();

    return (
        <div id="LandingPage">
            <div id="LandingPage-Body">
                <Carousel banners={store.banners} />
                <SearchEntry
                    onChange={(value) => navigate(`search?text=${value}`)}
                />
                {FeaturedProducts(store.products).length > 0 && (
                    <section id="LandingPage-Featured">
                        <h2>Destacados</h2>
                        <HorizontalLane>
                            {FeaturedProducts(store.products).map(
                                (product, i) => (
                                    <ProductCard key={i} product={product} />
                                )
                            )}
                        </HorizontalLane>
                    </section>
                )}
                <section id="LandingPage-Categories">
                    <h2>Categorías</h2>
                    <CategoriesGrid categories={store.categories} />
                </section>
            </div>
            <Footer />
        </div>
    );
};

export default LandingPage;
