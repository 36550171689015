import AddressInput from "../../components/Address/AddressInput";
import { tShoppingCartContext } from "../../api/context/ShoppingCartContext";
import { eDeliveryType } from "../../api/models/cartModel";
import { useStoreContext } from "../../api/context/StoreContext";

interface ContactInfoFormProps {
    shoppingCart: tShoppingCartContext;
}

export const ContactInfoForm = ({ shoppingCart }: ContactInfoFormProps) => {
    if (shoppingCart.cart.customer === undefined) {
        shoppingCart.cart.customer = {
            userId: "",
            name: "",
            email: "",
            phone: "",
        };
    }
    return (
        <section>
            <h3>Contacto</h3>

            {/* Nombre */}
            <div className="form-floating mb-3">
                <input
                    id="name"
                    value={shoppingCart.cart.customer.name}
                    type="text"
                    required
                    className={"form-control"}
                    placeholder="Nombre y apellidos"
                    onChange={(e) => {
                        shoppingCart.setCustomer({
                            ...shoppingCart.cart.customer,
                            name: e.currentTarget.value,
                        });
                    }}
                />
                <label htmlFor="name">Nombre y apellidos</label>
            </div>

            {/* eMail */}
            <div className="form-floating mb-3">
                <input
                    id="email"
                    value={shoppingCart.cart.customer.email}
                    type="email"
                    className="form-control"
                    onChange={(e) => {
                        shoppingCart.setCustomer({
                            ...shoppingCart.cart.customer,
                            email: e.currentTarget.value,
                        });
                    }}
                />
                <label htmlFor="email">Dirección de Email</label>
            </div>

            {/* Telefono */}
            <div className="form-floating">
                <input
                    id="phone"
                    value={shoppingCart.cart.customer.phone}
                    type="tel"
                    pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                    required
                    className={"form-control "}
                    placeholder="Teléfono celular"
                    onChange={(e) => {
                        shoppingCart.setCustomer({
                            ...shoppingCart.cart.customer,
                            phone: e.currentTarget.value,
                        });
                    }}
                />
                <label htmlFor="name">Teléfono celular</label>
                <div className="invalid-feedback">Teléfono celular.</div>
            </div>
        </section>
    );
};

interface DeliveryFormProps {
    shoppingCart: tShoppingCartContext;
}
export const DeliveryForm = ({ shoppingCart }: DeliveryFormProps) => {
    const { store } = useStoreContext();

    return (
        <>
            <section>
                <h3 className="mt-0">Forma de entrega</h3>
                <div>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault2"
                            disabled={store.pickupEnabled === false}
                            checked={
                                shoppingCart.cart.deliveryType ===
                                eDeliveryType.Pickup
                            }
                            onChange={(e) =>
                                shoppingCart.setDeliveryType(
                                    eDeliveryType.Pickup
                                )
                            }
                        />
                        <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault2"
                        >
                            Recoger en tienda
                        </label>
                    </div>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                            disabled={store.deliveryEnabled === false}
                            checked={
                                shoppingCart.cart.deliveryType ===
                                eDeliveryType.Delivery
                            }
                            onChange={(e) =>
                                shoppingCart.setDeliveryType(
                                    eDeliveryType.Delivery
                                )
                            }
                        />
                        <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault1"
                        >
                            Envío a domicilio
                        </label>
                    </div>
                </div>
            </section>

            {shoppingCart.cart.deliveryType === eDeliveryType.Delivery && (
                <section>
                    <h3>Domicilio de entrega</h3>
                    <AddressInput
                        address={shoppingCart.cart.deliveryAddress}
                        onChange={(address) => shoppingCart.setAddress(address)}
                    />
                </section>
            )}
        </>
    );
};
