import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useShoppingCart } from "../../api/context/ShoppingCartContext";
import LoadingComponent from "../../components/Common/LoadingComponent";
import { GetErrorMessage } from "../../api/helpers/apiHelper";
import { CreateOrder } from "../../api/services/ShoppingCartServices";
import { useSession } from "../../api/context/SessionContext";
import ErrorModal from "../../components/Common/ErrorModal";
import React from "react";
import { tOrder } from "../../api/models/orderModel";

const CheckoutCompletePage = () => {
    const shoppingCart = useShoppingCart();
    const session = useSession();

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const checkingOut = useRef(false); // Esto es para evitar que se ejecute el useEffect dos veces

    const [email, setEmail] = useState("");
    const [order, setOrder] = useState<tOrder | undefined>();

    useEffect(() => {
        async function DoCheckout() {
            if (shoppingCart.cart.items.length === 0) {
                navigate("./");
                return;
            }

            try {
                checkingOut.current = true;
                setLoading(true);

                const response = await CreateOrder(
                    shoppingCart,
                    session.getLoggedUser()!
                );

                if (!response.success) {
                    setErrorMessage(response.message);
                    return;
                }

                setEmail(shoppingCart.cart.customer.email);
                setOrder(response.order);

                shoppingCart.emptyCart();
            } catch (error) {
                setErrorMessage(GetErrorMessage(error));
                navigate("checkout");
                return;
            } finally {
                setLoading(false);
            }
        }

        if (shoppingCart.cart.items.length === 0) {
            navigate("checkout-pay");
            return;
        } else {
            if (!checkingOut.current) DoCheckout();
        }
    }, [checkingOut]);

    if (loading) {
        if (errorMessage) return <ErrorModal errorMessage={errorMessage} />;
        else return <LoadingComponent />;
    }

    return (
        <div
            className="p-2 d-flex flex-column align-items-center justify-content-center"
            style={{ height: "85vh" }}
        >
            <p className="fs-1">¡Gracias por tu compra!</p>
            <p className="fs-2 text-center">
                Tu pedido ha sido registrado con el número:{" "}
                <span className="text-primary">{order?.id}</span>
            </p>
            <p className="fs-3 text-center">
                Hemos recibido tu pedido y enviado una copia a tu correo:
                <span className="text-primary"> {email}</span>
            </p>
            <button
                className="btn btn-primary w-100 mt-4"
                onClick={() => navigate("..")}
            >
                Regresar a Inicio
            </button>
        </div>
    );
};

export default CheckoutCompletePage;
