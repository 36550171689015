import axios, { AxiosError, AxiosInstance } from "axios";
import { JoinUrl } from "./stringHelpers";
import { tenantId } from "../services/tenantServices";

export const APIBaseUrl = process.env.REACT_APP_API_URL;

export const BuildUrl = (path: string) => {
  if (!APIBaseUrl) throw new Error("API URL not defined");

  return JoinUrl(APIBaseUrl, path);
};

// Creates an axios instance with the store ID header
export const CreateAxiosInstance = (): AxiosInstance => {
  if (!tenantId) {
    console.log("Tenant ID not defined"); // throw new Error("Tenant ID not defined");
    window.location.href = "https://www.prontonube.com/store-not-found";
    return axios.create();
  }

  return axios.create({
    headers: {
      TenantCode: `${tenantId}`,
    },
  });
};

export const GetErrorMessage = (error: any) => {
  if (axios.isAxiosError(error)) {
    const axiosError = error as AxiosError;
    switch (error.response?.status) {
      case 400:
        return "Alguno de los datos enviados es inválido. Revise la información e intente de nuvevo.";
      case 401:
      case 403:
        return "No tiene permisos para realizar esta acción.";
      case 404:
        return "No se encontró el recurso solicitado.";
      case 500:
        return "Ocurrió un error interno en el servidor. Por favor intente de nuevo más tarde.";
      default:
        return `Ocurrió un error inesperado (${axiosError.message}). Por favor intente de nuevo más tarde.`;
    }
  }

  if (error.message) return error.message;

  console.log("Unexpected Error", error);
  return `Ocurrió un error inesperado. Por favor intente de nuevo más tarde.`;
};
