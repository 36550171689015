import { useRef, useState } from "react";
import { LoadingButton } from "../../components/Common/LoadingButton";
import { GetErrorMessage } from "../../api/helpers/apiHelper";
import { Login } from "../../api/services/UsersServices";
import { useSession } from "../../api/context/SessionContext";
import ErrorModal from "../../components/Common/ErrorModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import React from "react";

const LoginPage = () => {
  const session = useSession();
  const navigate = useNavigate();
  const [search, setSearch] = useSearchParams();

  const [eMail, setEmail] = useState("");
  const eMailInputRef = useRef<HTMLInputElement>(null);

  const [password, setPassword] = useState("");
  const passwordInputRef = useRef<HTMLInputElement>(null);

  const [loading, setLoading] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const IniciarSesion = async () => {
    if (!ValidarCuenta()) return;

    try {
      setLoading(true);
      const response = await Login(eMail, password);
      if (!response.success) {
        setErrorMessage(response.message);
        return;
      }

      if (session.logIn(response.token)) {
        let returnPage = search.get("returnPage");
        if (!returnPage) returnPage = "/";
        navigate(returnPage);
      }
    } catch (error) {
      setErrorMessage(GetErrorMessage(error));
      return;
    } finally {
      setLoading(false);
    }
  };

  const ValidarCuenta = () => {
    setShowValidation(true);
    if (!eMail) {
      (eMailInputRef.current as HTMLElement).focus();
      return false;
    }
    if (!password) {
      (passwordInputRef.current as HTMLElement).focus();
      return false;
    }

    setShowValidation(false);

    return true;
  };

  return (
    <div className="mt-4">
      <div className="flex-title">
        <h2>Iniciar sesión</h2>
      </div>

      <div className="form-floating mb-3">
        <input
          type="email"
          id="email"
          value={eMail}
          required
          className={
            "form-control " + (showValidation && !eMail ? " is-invalid" : "")
          }
          placeholder="nombre@ejemplo.com"
          ref={eMailInputRef}
          onChange={(e) => setEmail(e.currentTarget.value)}
        />
        <label htmlFor="email">eMail</label>
        <div className="invalid-feedback">Escriba su dirección de correo.</div>
      </div>
      <div className="form-floating mb-3">
        <input
          type="password"
          id="password"
          value={password}
          ref={passwordInputRef}
          required
          className={
            "form-control " + (showValidation && !password ? " is-invalid" : "")
          }
          placeholder="Contraseña"
          onChange={(e) => setPassword(e.currentTarget.value)}
        />
        <label htmlFor="password">Contraseña</label>
        <div className="invalid-feedback">Escriba su contraseña.</div>
      </div>

      <div className="d-flex justify-content-end">
        <LoadingButton
          isLoading={loading}
          className="btn btn-lg btn-primary"
          onClick={IniciarSesion}
        >
          Iniciar sesión
        </LoadingButton>
      </div>

      {errorMessage && (
        <ErrorModal
          errorMessage={errorMessage}
          onOkClick={() => setErrorMessage("")}
        />
      )}
    </div>
  );
};

export default LoginPage;
