import { Accordion, Alert } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import NavigateBackButton from "../../components/Navigation/NavigateBackButton";
import { useShoppingCart } from "../../api/context/ShoppingCartContext";
import { FormatCurrency } from "../../api/helpers/stringHelpers";

// import { PaymentElement } from "@stripe/react-stripe-js";
import { eDeliveryType, ePaymentMethod } from "../../api/models/cartModel";
import { useState } from "react";

const CheckoutPaymentPage = () => {
    const navigate = useNavigate();
    const shoppingCart = useShoppingCart();

    const [errorText, setErrorText] = useState("");

    const continuar = async () => {
        // switch of payment method
        if (shoppingCart.cart.paymentMethod === ePaymentMethod.Card) {
            //TODO: Validar longitud de tarjeta, fecha de expiración y CVC
            if (
                !(document.getElementById("cardNumber") as HTMLInputElement)
                    ?.value
            ) {
                setErrorText("Escriba el número de tarjeta");
                return;
            }
            if (
                !(document.getElementById("expiration") as HTMLInputElement)
                    ?.value
            ) {
                setErrorText("Escriba la fecha de expiración");
                return;
            }
            if (!(document.getElementById("cvc") as HTMLInputElement)?.value) {
                setErrorText("Escriba el CVC");
                return;
            }
        } else if (shoppingCart.cart.paymentMethod === ePaymentMethod.Cash) {
            // No requiere validaciones para efectivo
        } else {
            setErrorText("Seleccione un método de pago");
            return;
        }

        navigate("../checkout-complete");
    };

    return (
        <div className="p-2">
            <div className="py-4 bg-white d-flex">
                <NavigateBackButton onClick={() => navigate(-1)} />
                <h2 className="m-0 ms-2">Pago</h2>
            </div>

            <section className="mt-2 mb-2">
                <Accordion defaultActiveKey="">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            Total a pagar:{" "}
                            {FormatCurrency(shoppingCart.getTotal(), true)}
                        </Accordion.Header>
                        <Accordion.Body>
                            <div id="CheckoutPay--orderSummary">
                                <div className="row">
                                    <div className="col">
                                        <NavLink to="../checkout">
                                            Productos
                                        </NavLink>
                                    </div>
                                    <div className="col text-end">
                                        {FormatCurrency(
                                            shoppingCart.getSubTotal(),
                                            true
                                        )}
                                    </div>
                                </div>
                                {shoppingCart.cart.deliveryType ===
                                    eDeliveryType.Delivery && (
                                    <div className="row">
                                        <div className="col">
                                            Gastos de envío
                                        </div>
                                        <div className="col text-end">
                                            {FormatCurrency(
                                                shoppingCart.getDeliveryFee(),
                                                true
                                            )}
                                        </div>
                                    </div>
                                )}
                                {shoppingCart.getCouponApplied() && (
                                    <div className="row">
                                        <div className="col">Descuento</div>
                                        <div className="col text-end text-danger">
                                            {FormatCurrency(
                                                shoppingCart.getDiscount(),
                                                true
                                            )}
                                        </div>
                                    </div>
                                )}
                                <div className="row">
                                    <div className="col">Total</div>
                                    <div className="col text-end">
                                        {FormatCurrency(
                                            shoppingCart.getTotal(),
                                            true
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </section>

            <section className="mt-2 mb-2">
                <h2>Pago</h2>
                <div>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="radioCard"
                            id="radioCard"
                            checked={
                                shoppingCart.cart.paymentMethod ===
                                ePaymentMethod.Card
                            }
                            onChange={(e) =>
                                shoppingCart.setPaymentMethod(
                                    ePaymentMethod.Card
                                )
                            }
                        />
                        <label className="form-check-label" htmlFor="radioCard">
                            Tarjeta de crédito
                        </label>
                    </div>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="radioCash"
                            id="radioCash"
                            checked={
                                shoppingCart.cart.paymentMethod ===
                                ePaymentMethod.Cash
                            }
                            onChange={(e) =>
                                shoppingCart.setPaymentMethod(
                                    ePaymentMethod.Cash
                                )
                            }
                        />
                        <label className="form-check-label" htmlFor="radioCash">
                            Efectivo (contra-entrega)
                        </label>
                    </div>
                </div>
                <div className="mt-3">
                    {shoppingCart.cart.paymentMethod ===
                        ePaymentMethod.Card && (
                        <form className="mt-3">
                            {/*
            TODO: Activate https://stripe.com/docs/payments/accept-a-payment?platform=web&ui=elements#web-collect-payment-details
             <PaymentElement />
            */}
                            <div className="form-floating mb-3">
                                <input
                                    id="cardNumber"
                                    type="text"
                                    required
                                    className={"form-control"}
                                    placeholder="Número de tarjeta"
                                />
                                <label htmlFor="cardNumber">
                                    Número de tarjeta
                                </label>
                            </div>
                            <div className="form-floating mb-3">
                                <input
                                    id="expiration"
                                    type="text"
                                    required
                                    className={"form-control"}
                                    placeholder="Fecha de Expiración"
                                />
                                <label htmlFor="expiration">
                                    Fecha de Expiración
                                </label>
                            </div>

                            <div className="form-floating mb-3">
                                <input
                                    id="cvc"
                                    type="text"
                                    required
                                    className={"form-control"}
                                    placeholder="CVC"
                                />
                                <label htmlFor="cvc">CVC</label>
                            </div>
                        </form>
                    )}
                </div>
                <div className="d-grid">
                    {errorText && (
                        <Alert className="mt-4 mb-2 w-100" variant="danger">
                            {errorText}
                        </Alert>
                    )}

                    <button
                        className="btn btn-success"
                        onClick={() => continuar()}
                    >
                        {shoppingCart.cart.paymentMethod === ePaymentMethod.Cash
                            ? "Continuar"
                            : "Pagar"}
                    </button>
                </div>
            </section>
        </div>
    );
};

export default CheckoutPaymentPage;
