import {
    FunctionComponent,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useStoreContext } from "../../api/context/StoreContext";
import { Category } from "../../api/models/categoryModel";

import HorizontalLane from "../../components/Layout/HorizontalLane";
import ProductCard from "../../components/ProductCard/ProductCard";
import SearchEntry from "../../components/SearchEntry/SearchEntry";
import Footer from "../../components/Footer/Footer";

interface CategoryPageProps {}

const CategoryPage: FunctionComponent<CategoryPageProps> = () => {
    const { store } = useStoreContext();

    const selectedCategoryRef = useRef<null | HTMLDivElement>(null);
    const [category, setCategory] = useState<Category>();

    const selectedSubCategoryRef = useRef<null | HTMLDivElement>(null);
    const [subCategory, setSubCategory] = useState<null | Category>();

    const navigate = useNavigate();

    const params = useParams();

    useEffect(() => {
        async function init() {
            if (params.categoryName) {
                let category = store.categories.find(
                    (c) =>
                        c.name.toLocaleLowerCase() ===
                        params.categoryName?.toLocaleLowerCase()
                );

                if (category) {
                    handleSelectedCategoryChanged(category);
                    return;
                }
            }

            let category = store.categories[0];
            if (category) {
                if (!params.categoryName)
                    window.history.replaceState(
                        {},
                        "",
                        "/category/" + category.name
                    );
                handleSelectedCategoryChanged(category);
            }
        }
        init();
    }, []);

    useLayoutEffect(() => {
        /*  // Al abrir la pagina hace scroll a la categoria seleccionada
        if (selectedCategoryRef && selectedCategoryRef.current) {
            selectedCategoryRef.current.scrollIntoView({
                behavior: "smooth",
                inline: "start",
            });
        }

        if (selectedSubCategoryRef && selectedSubCategoryRef.current) {
            selectedSubCategoryRef.current.scrollIntoView({
                behavior: "smooth",
                inline: "start",
            });
        } */
    });

    const handleSelectedCategoryChanged = (category: Category) => {
        setCategory(category);

        window.history.replaceState({}, "", category.name);
        if (!category.subCategories || category.subCategories?.length === 0) {
            setSubCategory(null);
            return;
        }

        setSubCategory(category.subCategories[0]);
    };

    const filterProducts = () => {
        if (subCategory) {
            return store?.products.filter(
                (p) => p.productSubCategoryId === subCategory?.id
            );
        }

        return store?.products.filter(
            (p) => p.productCategoryId === category?.id
        );
    };

    return (
        <div id="CategoryPage">
            <div id="CategoryPage-body">
                <HorizontalLane>
                    {store?.categories.map((c) => (
                        <div
                            key={c.id}
                            ref={
                                c.id === category?.id
                                    ? selectedCategoryRef
                                    : null
                            }
                            className={`categoriesSelector ${
                                c.id === category?.id ? " selected" : ""
                            }`}
                            onClick={() => handleSelectedCategoryChanged(c)}
                        >
                            <img src={c.imageUrl} alt="" />
                            <div>{c.name}</div>
                        </div>
                    ))}
                </HorizontalLane>

                {category &&
                    category.subCategories &&
                    category.subCategories.length > 0 && (
                        <HorizontalLane>
                            {category.subCategories.map((s) => (
                                <div
                                    key={s.id}
                                    className={`subCategorySelector ${
                                        s.id === subCategory?.id
                                            ? " selected"
                                            : ""
                                    }`}
                                    ref={
                                        s.id === subCategory?.id
                                            ? selectedSubCategoryRef
                                            : null
                                    }
                                    onClick={() => setSubCategory(s)}
                                >
                                    {s.name}
                                </div>
                            ))}
                        </HorizontalLane>
                    )}

                <SearchEntry
                    onChange={(value) => navigate(`/search?text=${value}`)}
                />
                <div className="productsGrid">
                    {filterProducts().map((p) => (
                        <ProductCard product={p} key={p.id} />
                    ))}
                </div>
            </div>
            <div id="CategoryPage-footer">
                {" "}
                <Footer />
            </div>
        </div>
    );
};

export default CategoryPage;
