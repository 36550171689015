import { useNavigate } from "react-router-dom";
import { tShoppingCartContext } from "../../api/context/ShoppingCartContext";
import { FormatCurrency } from "../../api/helpers/stringHelpers";
import NotesEditor from "../../components/Notes/NotesEditor";
import { useState } from "react";
import AddToCart from "../../components/AddToCart/AddToCart";
import {
  Alert,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
} from "react-bootstrap";
import { LoadingButton } from "../../components/Common/LoadingButton";

export const CheckoutCartEmpty = () => {
  const navigate = useNavigate();

  return (
    <div id="CheckoutCartPage">
      <div id="CheckoutCartPage-cart-empty">
        <div className="no-records">No tiene productos en el carrito</div>
        <div id="CheckoutCartPage-cart-empty--button">
          <button className="btn-success" onClick={() => navigate("../")}>
            Agregar productos
          </button>
        </div>
      </div>
    </div>
  );
};

interface EmptyCartButtonProps {
  shoppingCart: tShoppingCartContext;
}
export const EmptyCartButton = ({ shoppingCart }: EmptyCartButtonProps) => {
  return (
    <button
      id="CheckoutCartPage-empty-cart--button"
      onClick={() => {
        if (
          window.confirm(
            "¿ Está seguro que desea quitar todos los productos de su pedido?"
          )
        )
          shoppingCart.emptyCart();
      }}
    >
      Vaciar carrito
    </button>
  );
};

interface ShoppingCartProductsListProps {
  shoppingCart: tShoppingCartContext;
}

export const ShoppingCartProductsList = ({
  shoppingCart,
}: ShoppingCartProductsListProps) => {
  const [editingNoteId, setEditingNoteId] = useState(0);

  return (
    <>
      {shoppingCart.cart.items.map((item) => (
        <div id="CheckoutCartPage-product" key={item.id}>
          <img
            id="CheckoutCartPage-product--img"
            src={item.product.thumbUrl}
            alt=""
          />
          <div id="CheckoutCartPage-product--info">
            <div id="CheckoutCartPage-product--name">{item.product.name}</div>
            <div id="CheckoutCartPage-product--amount-placeholder">
              <span id="CheckoutCartPage-product--amount">
                {FormatCurrency(item.amount(), true)}
              </span>
              {/*   <span id="CheckoutCartPage-product--uom">
                / {item.product.unitOfMeasure}
              </span> */}
            </div>
            <div id="CheckoutCartPage-product--actions">
              <button
                className="btn-primary-outline"
                onClick={() => setEditingNoteId(item.product.id)}
              >
                {!item.notes && (
                  <img src="/img/icons/notes-outline.png" alt="Sin notas" />
                )}
                {item.notes && (
                  <img src="/img/icons/notes-filled.png" alt="Notas" />
                )}
              </button>
              <div id="CheckoutCartPage-product--quantity">
                <AddToCart product={item.product} />
              </div>
            </div>
          </div>
        </div>
      ))}

      {editingNoteId > 0 && (
        <NotesEditor
          note={shoppingCart.getItemNotes(editingNoteId)}
          show={true}
          onSave={(note) => {
            shoppingCart.setItemNotes(editingNoteId, note);
            setEditingNoteId(0);
          }}
          onCancel={() => setEditingNoteId(0)}
        />
      )}
    </>
  );
};

interface ApplyCouponProps {
  shoppingCart: tShoppingCartContext;
}

export const ApplyCoupon = ({ shoppingCart }: ApplyCouponProps) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [couponCode, setCouponCode] = useState<string>("");
  const [errorText, setErrorText] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);

  const applyCoupon = async () => {
    setErrorText("");

    if (!couponCode) {
      setErrorText("Escriba el código del cupón a aplicar");
      return;
    }

    try {
      setLoading(true);
      const response = await shoppingCart.applyCoupon(couponCode);

      if (!response.success) {
        setErrorText(response.message!);
        return;
      }
    } catch (error: any) {
      console.log(error);
      if (error.message) setErrorText(error.message);
    } finally {
      setLoading(false);
    }
  };

  const removeCoupon = async () => {
    setErrorText("");

    if (!shoppingCart.getCouponApplied()) return;

    try {
      setLoading(true);
      await shoppingCart.removeCoupon();

      setCouponCode("");
    } catch (error: any) {
      console.log(error);
      if (error.message) setErrorText(error.message);
      else setErrorText("Ocurrió un error al quitar el cupón");
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <button className="btn-primary w-100" onClick={() => setShowModal(true)}>
        Aplicar cupón
      </button>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Aplicar cupón</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <FormLabel>Cupón:</FormLabel>
            <FormControl
              type="text"
              placeholder="Folio del cupón"
              value={couponCode}
              onChange={(e) => {
                setCouponCode(e.currentTarget.value);
                setErrorText("");
              }}
              isInvalid={couponCode === "" && errorText !== ""}
            />
          </FormGroup>
          {shoppingCart.getCouponApplied() && (
            <div className="pt-2 d-flex justify-content-between align-items-center">
              <div>
                Valor aplicado:{" "}
                <span className="text-danger fw-bold">
                  {FormatCurrency(shoppingCart.getDiscount(), true)}
                </span>
              </div>
              <button
                disabled={loading}
                className="btn btn-outline-danger"
                onClick={removeCoupon}
              >
                Quitar cupón
              </button>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {errorText && (
            <Alert className="w-100" variant="danger">
              {errorText}
            </Alert>
          )}

          <LoadingButton
            isLoading={loading}
            className="btn-success w-100"
            onClick={applyCoupon}
          >
            Aplicar
          </LoadingButton>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
