import { FunctionComponent, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import NavigateBackButton from "../../components/Navigation/NavigateBackButton";
import { useSession } from "../../api/context/SessionContext";
import SignUp from "./SignUp";

const LoginOrSignUpPage: FunctionComponent = () => {
  const session = useSession();
  const navigate = useNavigate();
  const [search, setSearch] = useSearchParams();

  const [signUpId, setSignUpId] = useState<string | null>(null);
  const [showSignUp, setShowSignUp] = useState(false);

  function onSignUpByMail() {
    setSignUpId(null);
    setShowSignUp(true);
  }

  function onSignUpByGoogle() {
    //TODO: Get google auth
    setSignUpId("dummy@google.com");
    setShowSignUp(true);
  }

  if (showSignUp)
    return <SignUp clientId={signUpId} onCancel={() => setShowSignUp(false)} />;

  return (
    <div>
      <div className="flex-title">
        <NavigateBackButton navigateToUrl="/checkout" />
        <h2>Ingresa o regístrate para continuar</h2>
      </div>

      <div className="p-4 text-center">
        <div>
          <div className="border-bottom border-success py-4 my-4">
            <button
              className="btn-success w-100"
              onClick={() => {
                navigate(`../login?returnPage=${search.get("returnPage")}`);

                //TODO: Mostrar error
              }}
            >
              Ya estoy registrado
            </button>
          </div>
          <div>
            {/*  <button
              className="btn-primary w-100 my-2"
              onClick={() => onSignUpByGoogle()}
            >
              Usar mi cuenta de Google
            </button>
            <button className="btn-primary w-100 my-2 active">
              Usar mi cuenta de Facebook
            </button>
            <button className="btn-dark w-100 my-2">
              Usar mi cuenta de Apple
            </button> */}
            <button
              className="btn-primary w-100 my-2"
              onClick={() => onSignUpByMail()}
            >
              Crear una nueva cuenta
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginOrSignUpPage;
