import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import SearchPage from "./pages/SearchPage/SearchPage";
import CategoryPage from "./pages/CategoryPage/CategoryPage";
import LandingPage from "./pages/LandingPage/LandingPage";

import "./styles/scss/stylesheet.scss";

import { Container } from "react-bootstrap";
import Header from "./components/Header/Header";
import { ShoppingCartProvider } from "./api/context/ShoppingCartContext";
import CheckoutCartPage from "./pages/Checkout/CheckoutCartPage";
import { StoreContextProvider } from "./api/context/StoreContext";
import CheckoutInfoPage from "./pages/Checkout/CheckoutInfoPage";
import { SessionContextProvider } from "./api/context/SessionContext";
import LoginOrSignUpPage from "./pages/Authentication/LoginOrSignUpPage";
import CheckoutPaymentPage from "./pages/Checkout/CheckoutPaymentPage";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutCompletePage from "./pages/Checkout/CheckoutCompletePage";
import LoginPage from "./pages/Authentication/LoginPage";
import LogoutPage from "./pages/Authentication/LogoutPage";
import { App } from "./app";

const stripePromise = loadStripe(
    "pk_test_51HdLx4GBWkDHCGABvqObRYgL9o4RVruLQJb0Zct5nuGjeXd2c0zkA8DdWyV9vHevaEQP27tIE74ynh5RG57vOdkw00hLlIwyms"
);

//TODO: GetClientSecret from API https://stripe.com/docs/payments/accept-a-payment?platform=web&ui=elements#web-collect-payment-details
const stripeOptions = {
    clientSecret:
        "sk_test_51HdLx4GBWkDHCGABaRIZzQWinyEDmujx9JViVRXMsrk7mVAWNOt5hYmovmPRkRCiv8oaziVGDn8X4c3k4nRFfbmz00RxAhZFZO",
};

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <React.StrictMode>
        <div id="index-div">
            <BrowserRouter>
                <StoreContextProvider>
                    <SessionContextProvider>
                        <ShoppingCartProvider>
                            <Routes>
                                <Route path=":tenantCode/*" element={<App />} />
                            </Routes>
                        </ShoppingCartProvider>
                    </SessionContextProvider>
                </StoreContextProvider>
            </BrowserRouter>
        </div>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
